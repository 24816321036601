import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { categories } from "../../data";

export default function Categories() {
  const navigate = useNavigate();
  const handleCategoryClick = (categoryName) => {
    navigate(`/Category?category=${encodeURIComponent(categoryName)}`);
  };

  return (
    <Box>
      <Box sx={{ margin: "2% 15%" }}>
        <Typography
          color="#e8e8e8"
          sx={{ fontSize: "clamp(15px,2vw,30px)", mb: 2 }}
        >
          Event Categories
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {categories.map((category, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: "#2A2A2A",
                width: "clamp(100px, 12vw, 200px)",
                height: "clamp(100px, 12vw, 200px)",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  height: "100%",
                  "&:hover": { bgcolor: "rgba(42, 136, 10, 0.5)" },
                  background: category.inclGradient,
                  borderRadius: "10px",
                  flexDirection: "column",
                  padding: 0,
                }}
                onClick={() => handleCategoryClick(category.name)}
              >
                <div style={{ position: "relative", height: "100%" }}>
                  <Typography
                    color="#e8e8e8"
                    fontFamily="PPObjectSans-Heavy"
                    sx={{
                      fontSize: "clamp(10px,1.2vw,20px)",
                      textAlign: "left",
                      overflow: "hidden",
                      whiteSpace: "wrap",
                      ml: 1,
                      mt: 1,
                    }}
                  >
                    {category.name}
                  </Typography>
                  <img
                    src={category.img}
                    alt={index + 1}
                    sx={{ position: "absolute", bottom: 0 }}
                  />
                </div>
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
