import React from "react";
import Navbar from "./Navbars/Navbar";
import MyCarousel from "./Carousel/MyCarousel";
import Focus from "./Focus/Focus";
import Categories from "./Categories/Categories";
import Footer from "../Footer/Footer";
import Gradients from "./Gradients";
import SpecificEvents from "../SpecificEvents/SpecificEvents";
import { Paper, Link, Box } from "@mui/material";
import Offer from "../Offer/Offer";

export default function HomePage() {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          background: "#2A880A",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Link
          href="/hostonspend"
          sx={{
            textAlign: "center",
            fontFamily: "PPObjectSans-Heavy",
            textDecoration: "none",
            color: "#E8E8E8",
          }}
        >
          Host on Spend.
        </Link>
      </Box>
      <MyCarousel />
      <Gradients />
      <Focus />
      <Categories />
      <SpecificEvents heading="Events near by you" cards={(null, null)} />
      <Paper
        sx={{
          background:
            "linear-gradient(180deg, #2A880A -84.63%, #1B1D1A 116.26%)",
          p: 0.5,
        }}
      >
        <SpecificEvents heading="Events on weekends" cards={(null, null)} />
      </Paper>
      <SpecificEvents heading="Technical Events" cards={(null, null)} />
      <SpecificEvents heading="Non-Technical Events" cards={(null, null)} />
      <Box sx={{ display: "flex", justifyContent: "center", mb: "5%" }}>
        <Offer width="clamp(150px,50vw,900px)" />
      </Box>
      <Paper
        sx={{
          background:
            "linear-gradient(180deg, #1A3E9A -84.63%, #1B1D1A 116.26%)",
          p: 0.5,
        }}
      >
        <SpecificEvents heading="Night celebration" cards={(null, null)} />
      </Paper>
      <SpecificEvents heading="Hackathon workshops" cards={(null, null)} />
      <Footer />
    </>
  );
}
