import React from "react";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Left from "./Components/Left";
import Heading from "./Components/Heading";
import FormInputField from "./Components/FormInputField";
import * as Style from "../style";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import LoadingSpinner from "../LoadingSpinner";
import Otp from "./Otp";
import { API_ENDPOINT } from "../../Constants";

const AccDetails = () => {
  const navigate = useNavigate();
  const { user, phone, name, setIsLoading, isLoading, logout ,otp} = useAuth();
  const [checked, setChecked] = React.useState([true, false]);
  const [apiError, setApiError] = useState(null);

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };
  
  const children = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        ml: 3,
        color: "#E8E8E8",
      }}
    >
      <FormControlLabel
        label="Terms and Conditions"
        name="TnC"
        control={
          <Checkbox
            size="small"
            checked={checked[0]}
            onChange={handleChange2}
            sx={Style.checkboxStyle}
          />
        }
      />
      <FormControlLabel
        label="Consent to receive SMS"
        name="Consent"
        control={
          <Checkbox
            size="small"
            checked={checked[1]}
            onChange={handleChange3}
            sx={Style.checkboxStyle}
          />
        }
      />
    </Box>
  );

  async function handleFormSubmit(values, setSubmitting) {
    setIsLoading(true)
    const apiUrl = `${API_ENDPOINT}/user/actionUser`;
    const postData = {
      name: values.name,
      email: values.email,
      mobileNumber: values.phone,
      dob: values.dob,
      city: values.city,
      sendSMS: false,
    };
    try {
      // Send data to the API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Assuming the API response contains token or other necessary data
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.isDataSaved) {
        navigate("/");
        otp();
      } else {
        setApiError(responseData.message);
        logout();
        navigate("/Login");
        console.log("else:" + responseData.message);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      // Display error to the user
      setApiError("An error occurred while sending data");
    }
    // sendData(apiUrl, postData);
    setSubmitting(false);
    setIsLoading(false)
  }

  return (
    <Grid
      container
      sx={{ height: "100vh" }}
      alignItems="center"
      justifyContent="center"
    >
      {/* Left */}
      <Grid item xs={12} sm={6}>
        <Left
          backgroundImage="linear-gradient(to left,  rgba(42, 136, 10,0.65), rgba(42, 136, 10,0.6))"
          background="radial-gradient(circle, rgba(42, 136, 10,0.7), rgba(42, 136, 10,0.65))"
          boxShadow="-200px 0 500px 100px rgba(42, 136, 10,0.6)"
        />
      </Grid>

      {/* Right */}
      <Grid item xs={12} sm={6} sx={{ flexGrow: 1 }}>
        <Paper elevation={0} sx={{ ...Style.rightPaper, height: "100%" }}>
          <Box sx={{ m: "8vw" }}>
            <Heading heading="Enter account details" />

            {/* Form with Validation */}
            <Formik
              initialValues={{
                email: user || "",
                phone: phone || "",
                name: name || "",
                dob: "",
                city: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .test(
                    "valid-email",
                    "Please enter a valid email",
                    function (value) {
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      return emailRegex.test(value); // Check if value matches the email regex
                    },
                  )
                  .required("Please enter your email "),

                phone: Yup.string()
                  .test(
                    "valid-mobile",
                    "Please enter a valid mobile number",
                    function (value) {
                      return Yup.string()
                        .length(10)
                        .matches(/^[0-9]+$/)
                        .isValidSync(value);
                    },
                  )
                  .required("Please enter your phone number"),

                name: Yup.string()
                  .required("Please enter your Name")
                  .min(6, "Please enter valid Name")
                  .max(128),

                city: Yup.string().required("Please enter your City"),

                dob: Yup.string().required(
                  "Please enter your date of birth in DD/MM/YY format",
                ),
              })}
              onSubmit={(values, { setSubmitting }) => {
                handleFormSubmit(values, setSubmitting);
              }}
            >
              {({ isSubmitting }) => (
                <Form autoComplete="off">
                  <Box>
                    <FormControl sx={{ mb: 2 }}>
                      <Typography variant="h4" sx={Style.formLabel}>
                        Name
                      </Typography>
                      <FormInputField name="name" placeholder="Name" />
                      <ErrorMsg name="name" />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ mb: 2 }}>
                      <Typography variant="h4" sx={Style.formLabel}>
                        Phone Number
                      </Typography>
                      <FormInputField
                        name="phone"
                        placeholder="Enter Phone Number"
                      />
                      <ErrorMsg name="phone" />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ mb: 2 }}>
                      <Typography variant="h4" sx={Style.formLabel}>
                        Email ID
                      </Typography>
                      <FormInputField name="email" placeholder="Enter Email" />
                      <ErrorMsg name="email" />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ mb: 2 }}>
                      <Typography variant="h4" sx={Style.formLabel}>
                        Date of Birth
                      </Typography>
                      <FormInputField
                        name="dob"
                        placeholder="Enter DD/MM/YYYY"
                      />
                      <ErrorMsg name="dob" />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl sx={{ mb: 2 }}>
                      <Typography variant="h4" sx={Style.formLabel}>
                        City
                      </Typography>
                      <FormInputField
                        name="city"
                        placeholder="Enter your city name"
                      />
                      <ErrorMsg name="city" />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControlLabel
                      sx={{ color: "#E8E8E8" }}
                      name="checkbox"
                      label="Accept All"
                      control={
                        <Checkbox
                          size="small"
                          checked={checked[0] && checked[1]}
                          indeterminate={checked[0] !== checked[1]}
                          onChange={handleChange1}
                          sx={Style.checkboxStyle}
                        />
                      }
                    />
                    {children}
                    <ErrorMsg name="checkbox" />
                  </Box>
                  {isLoading ? (
                    <LoadingSpinner size={35} color="#2A880A" />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      sx={Style.submitBtn}
                      disabled={isSubmitting}
                    >
                      Create account
                    </Button>
                  )}
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AccDetails;
