import { ADD_ITEM, DELETE_ITEM,STORE_USER_DATA } from "../action/SpendActionsTypes";


const initialState = {
  numOfItems: 0,
};

export const spendReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems + 1,
      };

    case DELETE_ITEM:
      return {
        ...state,
        numOfItems: state.numOfItems - 1,
      };
      case STORE_USER_DATA:
        return {
          ...state,
          userData: action.payload,
        };
        
    default:
      return state;
  }
};