import React from "react";
import { Typography, Box, Breadcrumbs, Link, styled } from "@mui/material";

const Separator = styled(Typography)(({ theme }) => ({
  color: "#2A880A",
}));

export default function BreadCrumbs({ links }) {
  return (
    <Box
      sx={{
        height: "clamp(25px,2.5vw,40px)",
        background:
          "linear-gradient(to right, rgba(42, 136, 10 , 10%) 10%, rgba(0, 0, 0, 0))",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<Separator>/</Separator>}
        sx={{
          ml: "10vw",
          fontSize: "clamp(8px,1vw,12px)",
          fontFamily: "PPObjectSans-Regular",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0em",
          textAlign: "left",
          textTransform: "uppercase",
        }}
      >
        {links.map((link, index) => (
          <Link
            key={index}
            underline="hover"
            color="#E8E8E8"
            href={link.url}
            aria-current={index === links.length - 1 ? "page" : undefined}
          >
            {link.label}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
