import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import SpendRoutes from "./SpendRoutes";
import { AuthProvider } from "./hooks/useAuth";
import React from "react";
import { HookProvider } from "./hooks/useCustomHook";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <HookProvider>
            <SpendRoutes />
          </HookProvider>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
