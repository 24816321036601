import React from "react";
import { Box } from "@mui/material";

export default function Gradients() {
  return (
    <Box>
      {/* Red Gradient */}
      <Box
        sx={{
          width: "100%",
          height: "65%",
          left: -1200,
          top: "115%",
          opacity: 0.16,
          background: "rgba(193, 17, 59, 0.6)",
          position: "absolute",
          borderRadius: "50%",
          boxShadow: "200px 0 100px 100px rgba(193, 17, 59,0.6)",
        }}
      />
      {/* Blue Gradient */}
      <Box
        sx={{
          width: "60%",
          height: "60%",
          top: "125%",
          right: "-25%",
          opacity: 0.16,
          transform: "rotate(30deg)",
          background: "rgba(26, 62, 154, 0.55)",
          position: "absolute",
          borderRadius: "50%",
          boxShadow: "-200px 0 100px 100px rgba(26, 62, 154,0.55)",
        }}
      />
    </Box>
  );
}
