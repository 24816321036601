import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2A880A",
    },
    secondary: {
      main: "#7E7E7E",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography fontFamily="PPObjectSans-Regular">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DetailTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          color: "#E8E8E8",
          fontFamily: "PPObjectSans-Regular",
        }}
      >
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Information"
              {...a11yProps(0)}
              sx={{
                color: "#7E7E7E",
                fontWeight: 400,
                letterSpacing: "0.02em",
                fontSize: "clamp(8px,1.8vw,15px)",
                textTransform: "none",
                width: "15vw",
              }}
            />
            <Tab
              label="Terms & Conditions"
              {...a11yProps(1)}
              sx={{
                color: "#7E7E7E",
                fontWeight: 400,
                letterSpacing: "0.02em",
                fontSize: "clamp(8px,1.8vw,15px)",
                textTransform: "none",
                width: "15vw",
              }}
            />
            <Tab
              label="Event FAQ's"
              {...a11yProps(2)}
              sx={{
                color: "#7E7E7E",
                fontWeight: 400,
                letterSpacing: "0.02em",
                fontSize: "clamp(8px,1.8vw,15px)",
                textTransform: "none",
                width: "15vw",
              }}
            />
          </Tabs>
          <hr
            style={{
              border: "0",
              height: "1.5px",
              backgroundImage:
                "linear-gradient(to right,  #2A880A,rgba(42, 136, 10, 1), rgba(0, 0, 0, 0))",
              margin: 0,
            }}
          />
        </Box>
        <CustomTabPanel value={value} index={0}>
          Information
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Terms & conditions details
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Event FAQ's
        </CustomTabPanel>
      </Box>
    </ThemeProvider>
  );
}
