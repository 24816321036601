import React from "react";
import { Box } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const images = [
  { img: "https://source.unsplash.com/1600x900/?nature", title: "Nature" },
  { img: "https://source.unsplash.com/1600x900/?cars", title: "Car" },
  { img: "https://source.unsplash.com/1600x900/?planes", title: "Plane" },
  { img: "https://source.unsplash.com/1600x900/?bikes", title: "Bike" },
];

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Transition speed (milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000, // Interval for automatic scrolling (milliseconds)
    cssEase: "linear", // Easing function for the animation
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          variableWidth: false, // Disable variable width on smaller screens to prevent horizontal scroll
        },
      },
    ],
  };

  return (
    <Box>
      <Slider {...settings}>
        {images.map((image, i) => (
          <div
            key={i}
            style={{
              padding: "4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={image.img} alt={`Slide ${i + 1}`} className="SlideImg" />
          </div>
        ))}
      </Slider>
    </Box>
  );
};

export default Carousel;
