import React from "react";
import { Typography } from "@mui/material";

export default function Heading({ heading }) {
  return (
    <Typography
      variant="h1"
      marginBottom="3vw"
      sx={{
        fontWeight: 400,
        fontFamily: "PPObjectSans-Regular",
        lineHeight: "5vh",
        color: "#E8E8E8",
        fontSize: "clamp(30px,2.7vw,2.7vw)",
      }}
    >
      {heading}
    </Typography>
  );
}
