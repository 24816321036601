import { React, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  Link,
  Grid,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Left from "./Components/Left";
import Heading from "./Components/Heading";
import FormInputField from "./Components/FormInputField";
import OtherLogin from "./Components/OtherLogin";
import * as Style from "../style";
import { useAuth } from "../../hooks/useAuth";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import Otp from "./Otp";
import LoadingSpinner from "../LoadingSpinner";
import AccDetails from "./AccDetails";
import { API_ENDPOINT } from "../../Constants";

const Login = () => {
  const navigate = useNavigate();
  const { login, showAccDetails, isLoading, setIsLoading } = useAuth();
  const [apiError, setApiError] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function handleFormSubmit(values, setSubmitting) {
    setIsLoading(true);
    const apiUrl = `${API_ENDPOINT}/auth/signin`;
    const postData = {
      email: values.username,
      otpRequired: true
    };

    return fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        if (responseData.isOtpSent && responseData.isUserAvailable) {
          login(values.username);
          setShowOTP(true);
          setApiError(null);
        } else {
          setApiError(responseData.message);
        }
        setSubmitting(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        setApiError("An error occurred while sending data");
        setSubmitting(false);
      });
  }

  return (
    <>
      {showOTP ? (
        <Otp />
      ) : showAccDetails ? (
        <AccDetails />
      ) : (
        <Grid
          container
          sx={{ height: "100vh" }}
          alignItems="center"
          justifyContent="center"
        >
          {/* Left */}
          <Grid item xs={12} sm={6}>
            <Left
              backgroundImage="linear-gradient(to left,  rgba(213, 145, 12,0.65), rgba(213, 145, 12,0.6))"
              background="radial-gradient(circle, rgba(213, 145, 12,0.55), rgba(213, 145, 12,0.65))"
              boxShadow="-200px 0 500px 12.5vh rgba(213, 145, 12,0.6)"
            />
          </Grid>

          {/* Right */}
          <Grid
            item
            xs={20}
            sm={6}
            sx={{ flexGrow: 1, maxWidth: "600px", margin: "auto" }}
          >
            <Paper elevation={0} sx={{ ...Style.rightPaper }}>
              <Box sx={{ m: "clamp(70px,180px,10vw)" }}>
                <Heading heading="Login" />
                {/* Form with Validation */}
                <Formik
                  initialValues={{
                    username: "",
                  }}
                  validationSchema={Yup.object({
                    username: Yup.string()
                      .test(
                        "valid-email-or-mobile",
                        "Please enter a valid email or mobile number",
                        function (value) {
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          return (
                            emailRegex.test(value) || // Check if value matches the email regex ||
                            Yup.string()
                              .length(10)
                              .matches(/^[0-9]+$/)
                              .isValidSync(value)
                          );
                        },
                      )
                      .required("Please enter your email or phone number"),
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    setIsSubmitting(true);
                    values.username = values.username.toLowerCase();
                    await handleFormSubmit(values, setSubmitting);
                    setIsSubmitting(false);
                  }}
                >
                  {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                      <Typography variant="h4" sx={{ ...Style.formLabel }}>
                        Enter Email or Phone Number
                      </Typography>
                      <FormControl
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <FormInputField
                          name="username"
                          placeholder="Enter Email or Phone Number"
                        />
                        {isLoading ? (
                          <LoadingSpinner size={40} color="#D5910C" />
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            sx={{
                              bgcolor: "#2A880A",
                              width: "auto",
                              height: "40px",
                              "&:hover": { bgcolor: "rgba(42, 136, 10, 0.5)" },
                            }}
                            disabled={isSubmitting}
                          >
                            <EastIcon />
                          </Button>
                        )}
                      </FormControl>
                      {values.username && apiError && (
                        <Typography
                          variant="body2"
                          style={{ color: "#C1113B", fontSize: "14px" }}
                        >
                          {apiError}
                        </Typography>
                      )}
                      <ErrorMsg name="username" />
                    </Form>
                  )}
                </Formik>

                {/* Existing user link */}
                <Box sx={{ display: "flex", mt: "20px" }}>
                  <Typography
                    variant="h4"
                    marginBottom={1}
                    sx={Style.formLabel}
                  >
                    New User?{" "}
                  </Typography>
                  <span
                    onClick={() => navigate("/signup")}
                    style={Style.linkText}
                  >
                    Sign up here
                  </span>
                </Box>

                {/* Other login Buttons */}
                <OtherLogin setSubmitting={setIsSubmitting} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Login;
