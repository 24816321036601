import {
  Typography,
  Box,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import { getFilteredEvents, getFormattedDateRange } from "../data";
import Navbar from "../HomePage/Navbars/Navbar";
import * as Style from "../style";
import { Instagram, Facebook } from "lucide-react";
import LinkIcon from "@mui/icons-material/Link";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import XIcon from "@mui/icons-material/X";
import DetailTabs from "./Components/DetailTabs";
import Carousel from "./Components/Carousel";
import Offer from "../Offer/Offer";
import { useCustomHook } from "../../hooks/useCustomHook";
import { useAuth } from "../../hooks/useAuth";

export default function EventScreen() {
  const { isLoggedin } = useAuth();
  const nav = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("event_id");
  const event = getFilteredEvents(null, eventId)[0];
  const dateRange = getFormattedDateRange(event.date.from, event.date.to);
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyLink = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        console.log("Link copied to clipboard:", currentURL);
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
      });
  };

  const handleBookNow = () => {
    nav(`/booknow?event_id=${encodeURIComponent(event.event_id)}`);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => setIsCopied(false), 1000);
    return () => clearTimeout(timeoutId);
  }, [isCopied]);

  return (
    <Box>
      <Navbar />
      <BreadCrumbs
        links={[
          { label: "Home", url: "/" },
          {
            label: event.category.name,
            url: `/Category?category=${encodeURIComponent(event.category.name)}`,
          },
          {
            label: event.title,
            url: `/Event?event_id=${encodeURIComponent(event.event_id)}`,
          },
        ]}
      />

      <Box sx={{ m: "5% 15%" }}>
        <Typography
          sx={{
            fontFamily: "PPObjectSans-Regular",
            fontSize: "4.3vw",
            fontWeight: 400,
            lineHeight: "5vw",
            letterSpacing: "-0.02em",
            textAlign: "left",
            color: "#E8E8E8",
          }}
        >
          {event.title}
        </Typography>
        <Box
          sx={{
            background:
              "linear-gradient(to right, rgba(42, 136, 10,1), rgba(0, 0, 0, 0))",
            width: "15vw",
            mt: "1%",
          }}
        >
          <Typography
            fontFamily="PPObjectSans-Regular"
            color="#E8E8E8"
            fontSize="1vw"
            p="0% 3%"
          >
            {event.category.name}
          </Typography>
        </Box>
        {/* Details */}
        <Box
          sx={{
            color: "#E8E8E8",
            fontFamily: "PPObjectSans-Heavy",
            fontSize: "0.8vw",
            mt: "5%",
          }}
        >
          <span>{dateRange}</span> | <span>{event.time}</span> |{" "}
          <span>{event.venue}</span>
        </Box>
        {/* Description */}
        <Box>
          <Typography
            variant="body1"
            color="#e8e8e8"
            m="3% 0%"
            fontFamily="PPObjectSans-Regular"
            fontSize="1vw"
            sx={{ width: "40vw" }}
          >
            {event.description}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: "10%",
            m: "5% 15%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              mt: "20%",
              boxShadow: "-100px 0 100px 30px rgba(42, 136, 10,0.18)",
              position: "absolute",
              width: "90%",
              height: "70%",
              borderRadius: "50%",
            }}
          />
          <img
            src={event.image_loc.card}
            style={{
              height: "32vw",
              width: "25vw",
              position: "relative",
            }}
            alt={event.event_id}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "1.5px",
              backgroundImage:
                "linear-gradient(to top, rgba(0, 0, 0, 0), #2A880A, rgba(0, 0, 0, 0))",
            }}
          />
        </Box>
        <Box
          display="flex"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            width: "40%",
            mt: "20px",
          }}
        >
          <Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                ...Style.submitBtn,
                fontSize: "clamp(5px,0.75vw,14px)",
              }}
              onClick={handleBookNow}
            >
              Book now
            </Button>
          </Box>
          {/* Share */}
          <Box sx={{ display: "flex", ml: "20%" }}>
            <IconButton onClick={handleCopyLink}>
              {isCopied ? (
                <CheckCircleOutlineIcon
                  sx={{ color: "#E8E8E8", width: "1vw" }}
                />
              ) : (
                <LinkIcon
                  sx={{
                    transform: "rotate(50deg)",
                    color: "#E8E8E8",
                    cursor: "pointer",
                    width: "1vw",
                  }}
                />
              )}
            </IconButton>
            <Snackbar open={isCopied} autoHideDuration={1000}>
              <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
                Copied!
              </Alert>
            </Snackbar>
            <IconButton
              sx={{
                transition: "transform 0.3s",
                "&:active": {
                  transform: "scale(0.9)",
                },
              }}
            >
              <Instagram color="#E8E8E8" size="1vw" />
            </IconButton>
            <IconButton
              sx={{
                transition: "transform 0.2s",
                "&:active": {
                  transform: "scale(0.9)",
                },
              }}
            >
              <Facebook color="#E8E8E8" size="1vw" />
            </IconButton>
            <IconButton
              sx={{
                transition: "transform 0.3s",
                "&:active": {
                  transform: "scale(0.9)",
                },
              }}
            >
              <XIcon
                sx={{
                  color: "#E8E8E8",
                  cursor: "pointer",
                  width: "1vw",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Offer */}
      <Box sx={{ m: "5% 15%" }}>
        <Offer width="clamp(190px,35vw,500px)" />
      </Box>

      {/* Posters */}
      <Box>
        <Carousel />
      </Box>

      {/* artist details */}
      <Box sx={{ m: "5% 15%" }}>
        <DetailTabs />
      </Box>
      {/* schedule */}
    </Box>
  );
}
