import React from "react";
import { Box, Typography } from "@mui/material";
import * as Style from "../../style";

export default function Left({ backgroundImage, background, boxShadow }) {
  return (
    <>
      <Box sx={Style.leftSection}>
        <Box
          position="absolute"
          display="flex"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography variant="h1" sx={{ ...Style.leftLogo, color: "#E8E8E8" }}>
            Spend
          </Typography>
          <Typography variant="h1" sx={{ ...Style.leftLogo, color: "#2A880A" }}>
            .
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Gradient */}
        <Box
          sx={{
            backgroundImage: backgroundImage,
            background: background,
            border: "1px 0.5 #D5910C ",
            boxShadow: boxShadow,
            width: "50%",
            height: "50%",
            position: "absolute",
            opacity: "16%",
            left: "35%",
            borderRadius: "60%",
          }}
        />
      </Box>
    </>
  );
}
