import { Box, Link, Paper, Typography } from "@mui/material";
import React from "react";
import Navbar from "../HomePage/Navbars/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { getFilteredEvents, getFormattedDateRange } from "../data";
import DoneIcon from "@mui/icons-material/Done";
import { useCustomHook } from "../../hooks/useCustomHook";
import QRCode from "react-qr-code";

export default function Success() {
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("event_id");
  const event = getFilteredEvents(null, eventId)[0];
  const dateRange = getFormattedDateRange(event.date.from, event.date.to);
  const tickets = new URLSearchParams(location.search).get("passes");
  const { storeData, userData } = useCustomHook();
  const booking_id = new URLSearchParams(location.search).get("booking_id");
  return (
    <Box>
      <Navbar all={false} />
      <Box sx={{ display: "flex", m: "5% 15%", gap: "10%" }}>
        {/* left content */}
        <Box sx={{}}>
          <Box
            sx={{
              boxShadow: "0 0 50px 40px rgba(42, 136, 10,0.6)",
              borderRadius: "50%",
              bgcolor: "#2A880A",
              width: "4vw",
              height: "4vw",
              p: "2%",
              m: "10% 0% 13% 16%",
            }}
          >
            <DoneIcon
              sx={{
                fontSize: "4vw",
                color: "#E8E8E8",
              }}
            />
          </Box>
          <Box sx={{ ml: "12%" }}>
            <Typography
              sx={{
                color: "#E8E8E8",
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(10px,2vw,50px)",
              }}
            >
              Great! You have spent with
              <span style={{ fontFamily: "Baloo Chettan" }}> Spend</span>
              <span style={{ fontFamily: "Baloo Chettan", color: "#2A880A" }}>
                .
              </span>
            </Typography>
            <Box
              sx={{
                color: "#E8E8E8",
                fontSize: "clamp(5px,1vw,20px)",
                FontFamily: "PPObjectSans-Regular",
                mt: "15%",
              }}
            >
              <Link
                sx={{
                  color: "#D5910C",
                  textDecorationColor: "#D5910C",
                  cursor: "pointer",
                }}
                href="/"
              >
                Click here
              </Link>
              <span> to go back to home page.</span>
            </Box>
          </Box>
        </Box>
        {/* right Content */}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "#2A880A",
            p: "1%",
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <img
              src={event.image_loc.card}
              style={{
                height: "10vw",
                width: "8vw",
                position: "relative",
              }}
              alt={event.event_id}
            />
            <Box sx={{ ml: 1 }}>
              <Typography
                color="#2A880A"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(10px,2vw,30px)",
                }}
              >
                {event.title}
              </Typography>
              <Box
                sx={{
                  color: "#E8E8E8",
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "0.8vw",
                  fontWeight: 400,
                }}
              >
                <span>{dateRange}</span> | <span>{event.time}</span> |{" "}
                <span>{event.venue}</span>
              </Box>
              <Typography
                color="#2A880A"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,1vw,16px)",
                  mt: 2,
                }}
              >
                Passes: {tickets}
              </Typography>
            </Box>
            <Box
              sx={{
                ml: 10,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                color="#E8E8E8"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(4px,0.7vw,10px)",
                }}
              >
                Scan me to verify
              </Typography>
              <QRCode value={booking_id} size="7vw" bgColor="#E8E8E8" />
            </Box>
          </Box>

          <Box>
            <Box>
              <Typography
                color="#E8E8E8"
                sx={{
                  fontFamily: "PPObjectSans-Heavy",
                  fontSize: "clamp(5px,1vw,36px)",
                  fontWeight: 400,
                  mt: 2,
                }}
              >
                PASSES FOR
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                columnGap: 8,
              }}
            >
              <Typography
                color="#E8E8E8"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,1vw,14px)",
                }}
              >
                Name
              </Typography>
              <Typography
                color="#E8E8E8"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,1vw,14px)",
                }}
              >
                Phone
              </Typography>
              <Typography
                color="#E8E8E8"
                sx={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,1vw,14px)",
                }}
              >
                Email
              </Typography>
            </Box>
            <hr style={{ border: "1.5px solid", borderColor: "#2A880A" }} />
          </Box>
          {userData.map((value, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  columnGap: 8,
                }}
              >
                <Typography
                  color="#E8E8E8"
                  sx={{
                    fontFamily: "PPObjectSans-Regular",
                    fontSize: "clamp(5px,1vw,14px)",
                  }}
                >
                  {value.name}
                </Typography>
                <Typography
                  color="#E8E8E8"
                  sx={{
                    fontFamily: "PPObjectSans-Regular",
                    fontSize: "clamp(5px,1vw,14px)",
                  }}
                >
                  {value.phone}
                </Typography>
                <Typography
                  color="#E8E8E8"
                  sx={{
                    fontFamily: "PPObjectSans-Regular",
                    fontSize: "clamp(5px,1vw,14px)",
                  }}
                >
                  {value.email}
                </Typography>
              </Box>
              <hr style={{ border: "1px solid", borderColor: "#2A880A" }} />
            </Box>
          ))}
          <Box>
            <Typography
              color="#E8E8E8"
              sx={{
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(5px,0.8vw,14px)",
              }}
            >
              NOTE:
            </Typography>
            <ol style={{ paddingLeft: 15 }}>
              <li
                style={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,0.8vw,14px)",
                  color: "#E8E8E8",
                }}
              >
                You can access your pass anytime from "My Bookings"
              </li>
              <li
                style={{
                  fontFamily: "PPObjectSans-Regular",
                  fontSize: "clamp(5px,0.8vw,14px)",
                  color: "#E8E8E8",
                }}
              >
                It is mandatory to show the passes with QR code at the venue
              </li>
            </ol>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
