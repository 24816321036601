import { ADD_ITEM, DELETE_ITEM,STORE_USER_DATA } from "./SpendActionsTypes";


const addItem = () => {
  return {
    type: ADD_ITEM,
  };
};

const deleteItem = () => {
  return {
    type: DELETE_ITEM,
  };
};

const storeUserData = (userData) => ({
  type: STORE_USER_DATA,
  payload: userData,
});

export { addItem, deleteItem, storeUserData };

