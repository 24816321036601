import React from "react";
import { Button, Typography } from "@mui/material";
import { getFilteredEvents, categories, getFormattedDateRange } from "../data";
import { useNavigate } from "react-router-dom";
import "./EventCard.css";

export default function EventCard({ categoryName, limit }) {
  const navigate = useNavigate();
  const filteredEvents = getFilteredEvents(categoryName);
  const eventsToDisplay = limit
    ? filteredEvents.slice(0, limit)
    : filteredEvents; // Apply limit if provided

  const getCategoryInfo = (name) => {
    return categories.find((category) => category.name === name);
  };

  return (
    <section
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
      }}
    >
      {eventsToDisplay.map((event, index) => {
        const categoryInfo = getCategoryInfo(event.category.name);

        return (
          <Button
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "left",
              padding: 0,
              maxWidth: 234,
              position: "relative", // Ensure relative positioning
            }}
            onClick={() => {
              navigate(`/Event?event_id=${encodeURIComponent(event.event_id)}`);
            }}
          >
            {/* Container div for image and Typography */}
            <div style={{ position: "relative", marginBottom: "10px" }}>
              <img
                src={event.image_loc.card}
                alt=""
                style={{
                  height: "auto",
                  width: "clamp(70px,16vw,200px)",
                  objectFit: "cover",
                }}
              />
              {/* Typography component */}
              <Typography
                sx={{
                  position: "absolute",
                  bottom: 1,
                  backgroundColor: categoryInfo
                    ? categoryInfo.color
                    : "#2A880A",
                  padding: "0.05% 0.07%",
                  borderRadius: "2px",
                  color: "#E8E8E8",
                  fontFamily: "PPObjectSans-Heavy",
                  width: "100%",
                  textAlign: "center",
                  fontSize: "1vw",
                }}
              >
                {event.category.name}
              </Typography>
            </div>
            <Typography
              fontFamily="PPObjectSans-Heavy"
              color="#E8E8E8"
              sx={{
                fontSize: "1.15vw",
                lineHeight: "24px",
                fontWeight: 800,
              }}
            >
              {event.title}
            </Typography>
            <Typography
              fontFamily="PPObjectSans-Regular"
              color="#7e7e7e"
              flexWrap="wrap"
              sx={{ fontSize: "0.75vw", width: "15vw" }}
            >
              <span>
                {event.venue} |{" "}
                {getFormattedDateRange(event.date.from, event.date.to)}
              </span>
            </Typography>
          </Button>
        );
      })}
    </section>
  );
}
