import React, { createContext, useContext, useMemo } from "react";
import { useLocalStorage } from "./useLocalStorage";

const CustomContext = createContext();

export const HookProvider = ({ children }) => {
  const [page, setPage] = useLocalStorage("page", 0);
  const [links, setLinks] = useLocalStorage("links", null);
  const [userData, setUserData] = useLocalStorage("userData", null);

  const storeData = (data) => {
    setUserData(data);
  };

  const step2 = () => {};

  const value = useMemo(
    () => ({
      page,
      setPage,
      links,
      storeData,
      userData,
    }),
    [page, setPage, links, setLinks, userData], // Correct dependencies for useMemo
  );

  return (
    <CustomContext.Provider value={value}>{children}</CustomContext.Provider>
  );
};

export const useCustomHook = () => {
  const context = useContext(CustomContext);
  if (!context) {
    throw new Error("useCustomHook must be used within an HookProvider");
  }
  return context;
};
