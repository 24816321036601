import { Typography, Box, Snackbar, Alert } from "@mui/material";
import React, { useState, useEffect } from "react";

const offers = [
  {
    id: 1,
    offer: 50,
    details: "up to $10 on your first purchase with Spend.",
    code: "FIRST50",
    tnc: "These are the terms and conditions that applies to this code.",
  },
  {
    id: 2,
    offer: 25,
    details: "up to $10 on your first purchase with Spend.",
    code: "DAY25",
    tnc: "These are the terms and conditions that applies to this code.",
  },
  {
    id: 3,
    offer: 10,
    details: "up to $10 on your first purchase with Spend.",
    code: "DAILY10",
    tnc: "These are the terms and conditions that applies to this code.",
  },
];
export default function Offer(width) {
  const [isCopied, setIsCopied] = useState(false);
  const maxOfferObject = offers.reduce((max, offer) =>
    offer.offer > max.offer ? offer : max,
  );
  const handleCopyCode = () => {
    const code = maxOfferObject.code;
    navigator.clipboard
      .writeText(code)
      .then(() => {
        console.log("Link copied to clipboard:", code);
        setIsCopied(true);
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
      });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => setIsCopied(false), 1000);
    return () => clearTimeout(timeoutId);
  }, [isCopied]);

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "#D5910C",
        borderRadius: 2,
        background:
          "linear-gradient(243.72deg, #D5910C -210.57%, rgba(213, 145, 12, 0) 97.45%)",
        p: "2%",
        width: width,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={handleCopyCode}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          sx={{
            color: "#D5910C",
            fontFamily: "Baloo Chettan",
            fontSize: "clamp(15px, 3.2vw, 50px)",
          }}
        >
          Get {maxOfferObject.offer}% OFF
        </Typography>
        <Typography
          sx={{
            color: "#E8E8E8",
            fontFamily: "PPObjectSans-Regular",
            flexWrap: "wrap",
            fontSize: "clamp(8px, 1.5vw, 16px)",
            width: "95%",
          }}
        >
          {maxOfferObject.details}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            background: "#D5910C",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 2,
            width: "clamp(63px,14vw,220px)",
            p: "2%",
          }}
        >
          <Typography
            sx={{
              color: "#1B1D1A",
              fontFamily: "PPObjectSans-Regular",
              fontSize: "clamp(8px, 1.2vw, 16px)",
            }}
          >
            Use Code
          </Typography>
          <Typography
            variant="h3"
            sx={{
              color: "#1B1D1A",
              fontFamily: "Baloo Chettan",
              fontSize: "clamp(12px, 3.5vw, 40px)",
            }}
          >
            {maxOfferObject.code}
          </Typography>
        </Box>
        <span
          style={{
            textAlign: "right",
            color: "#E8E8E8",
            fontSize: "clamp(8px, 0.8vw, 12px)",
            m: 0,
          }}
        >
          t&c apply
        </span>
      </Box>

      <Snackbar open={isCopied} autoHideDuration={1000}>
        <Alert severity="success" variant="filled" sx={{ width: "100%" }}>
          Copied Code!
        </Alert>
      </Snackbar>
    </Box>
  );
}
