export const formLabel = {
  fontWeight: 400,
  fontSize: "clamp(15px,1vw,1vw)",
  fontFamily: "PPObjectSans-Regular",
  lineHeight: "2vh",
  color: "#E8E8E8",
  mb: 1,
};

export const linkText = {
  fontWeight: 400,
  fontFamily: "PPObjectSans-Regular",
  lineHeight: "15px",
  fontSize: "15px",
  color: "#D5910C",
  textDecoration: "underline",
  textDecorationColor: "#D5910C",
  cursor: "pointer",
};

export const otherLoginBtn = {
  bgcolor: "rgba(232, 232, 232, 0.1)",
  marginBottom: "23px",
  width: "clamp(300px,25vw,480px)",
  height: "40px",
  borderRadius: "23px",
  "&:hover": {
    bgcolor: "rgba(42, 136, 10, 0.5)",
  },
};

export const otherLogin = {
  fontWeight: 400,
  fontFamily: "PPObjectSans-Regular",
  lineHeight: "14px",
  color: "#E8E8E8",
  fontSize: "14px",
};

export const submitBtn = {
  bgcolor: "#2A880A",
  width: "auto",
  height: "clamp(2.3vw,5vh,3vw)",
  "&:hover": { bgcolor: "rgba(42, 136, 10, 0.5)" },
  padding: "1.8vh 4.5vh 1.8vh 4.5vh",
  marginRight: "2.5vh",
  borderRadius: "10px",
  fontSize: "clamp(10px,0.75vw,14px)",
  font: "PPObjectSans-Heavy",
  fontWeight: 800,
};

export const rightSection = {
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  overflow: "hidden",
  width: "5%",
};

export const rightPaper = {
  padding: "10 5",
  backgroundColor: "#1B1D1A",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export const leftSection = {
  flexGrow: 1,
  backgroundColor: "#1B1D1A",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  m: 0,
};

export const leftLogo = {
  fontFamily: "Baloo Chettan",
  fontSize: "clamp(9vw,20vw,15vh)",
  fontWeight: 400,
};

export const checkboxStyle = {
  color: "#2A880A",
  borderRadius: "50%",
  "&.Mui-checked": {
    color: "#2A880A",
    borderRadius: "50%",
  },
  "&.MuiCheckbox-indeterminate": {
    color: "#2A880A",
    borderRadius: "50%",
  },
  "& .MuiSvgIcon-root": {
    borderRadius: "50%",
  },
};