import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function AfterLogin(props) {
  const navigate = useNavigate();

  const handleSignIn = () => {
    // Perform sign out logic here
    navigate("/Signup");
  };
  const navigateToLogin = () => {
    // navigate to /Login
    navigate("/Login");
  };
  return (
    <>
      {/* Sign In button */}
      <Button
        variant="outlined"
        onClick={handleSignIn}
        sx={{
          marginRight: "18px",
          width: "100px",
          height: "33px",
          borderRadius: "24px",
          borderColor: "#2A880A",
          color: "#2A880A",
          "&:hover": { color: "#2A880A", borderColor: "#2A880A" },
          fontFamily: "PPObjectSans-Heavy",
          fontSize: "12px",
          lineHeight: "10px",
        }}
      >
        Sign in
      </Button>

      {/* Login button */}
      <Button
        variant="contained"
        onClick={navigateToLogin}
        className="Button"
        sx={{
          marginRight: "18px",
          height: "33px",
          borderRadius: "24px",
          backgroundColor: "#2A880A",
          width: "100px",
          "&:hover": { backgroundColor: "#2A880A" },
          fontFamily: "PPObjectSans-Heavy",
          fontSize: "10px",
          textAlign: "center",
          lineHeight: "12px",
        }}
      >
        Login
      </Button>
    </>
  );
}
