import { React } from "react";
import { useNavigate } from "react-router-dom";
import { Box, AppBar, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import MenuBar from "./Components/MenuBar";
import Location from "./Components/Location";
import AfterLogin from "./Components/AfterLogin";
import BeforeLogin from "./Components/BeforeLogin";
import Search from "./Components/Search";
import { useAuth } from "../../../hooks/useAuth";

export default function Navbar({ all = true }) {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const Logo = {
    fontFamily: "Baloo Chettan",
    fontSize: "clamp(25px,2vw,2vw)",
    fontWeight: 100,
    lineHeight: "2vh",
    display: "flex",
  };
  return (
    <Box>
      <AppBar
        sx={{
          justifyContent: "flex-start",
          height: "65px",
          position: "static",
          background: "#1B1E1A",
          display: "flex",
          borderBottom: 2,
          borderBottomColor: "#2A880A !important",
          boxShadow: 0,
          width: "100%",
        }}
      >
        <Toolbar>
          {/* Logo */}
          <Box
            sx={{ cursor: "pointer", display: "flex" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Typography sx={{ ...Logo, color: "#E8E8E8", marginLeft: "13vw" }}>
              Spend
            </Typography>
            <Typography sx={{ ...Logo, color: "#2A880A", marginRight: "4vw" }}>
              .
            </Typography>
          </Box>
          {all ? (
            <>
              {/* Search */}
              <Search />

              {/* Location */}
              <Location />

              {/* Conditional rendering based on authentication */}
              {isLoggedIn ? <AfterLogin /> : <BeforeLogin />}

              {/* Menu */}
              <MenuBar />
            </>
          ) : (
            <></>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
