import React from "react";
import { useLocation } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import Navbar from "../HomePage/Navbars/Navbar";
import BreadCrumbs from "../BreadCrumbs";
import { categories } from "../data";
import EventCard from "../EventCard/EventCard";

const Category = () => {
  const location = useLocation();
  const categoryName = new URLSearchParams(location.search).get("category");
  const index = categories.findIndex(
    (category) => category.name === categoryName,
  );

  return (
    <>
      <Navbar />
      <BreadCrumbs
        links={[
          { label: "Home", url: "/" },
          {
            label: categoryName,
            url: `/Category?category=${encodeURIComponent(categoryName)}`,
          },
        ]}
      />
      <Box
        sx={{
          position: "relative",
          height: "clamp(120px,25vw,350px)",
          background: categories[index].linearGradient,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h3"
          color="#E8E8E8"
          fontFamily="Baloo Chettan"
          sx={{ fontSize: "clamp(20px,6vw,120px)", m: "5% 10% 3% 10%" }}
        >
          {categoryName}
        </Typography>
        <Typography
          variant="h3"
          color="#E8E8E8"
          sx={{
            fontSize: "clamp(8px,1.75vw,18px)",
            m: "0% 30% 0% 10%",
            fontFamily: "PPObjectSans-Regular",
          }}
        >
          {index !== -1 ? categories[index].description : ""}
        </Typography>
      </Box>
      <Box sx={{m:"5% 15%",}}>
        <EventCard categoryName={categoryName} />
      </Box>
    </>
  );
};

export default Category;
