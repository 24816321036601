import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { events } from "../../../data";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.trim() !== "") {
        const filteredEvents = events.filter(
          (event) =>
            event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            event.event_id.toLowerCase().includes(searchTerm.toLowerCase())||
            event.venue.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setSearchResults(filteredEvents);
      } else {
        setSearchResults([]);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <div style={{ position: "relative" }}>
      <Paper
        component="form"
        sx={{
          p: "0.1vw 0.3vw",
          display: "flex",
          width: "32.5vw",
          maxWidth: "620px",
          borderRadius: "0.8vw",
          backgroundColor: "#E8E8E8",
          height: "40px",
          opacity: "90%",
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, fontFamily: "PPObjectSans-Regular" }}
          placeholder="Search by college, event, and location"
          inputProps={{ "aria-label": "Search College/Event/Location" }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <IconButton
          type="button"
          sx={{ p: "1vh", scale: "150%", opacity: "0.3" }}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>

      {/* Display search results */}
      {searchResults.length > 0 && (
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            position: "absolute",
            width: "32.5vw",
            maxWidth: "620px",
            backgroundColor: "rgba(232, 232, 232, 0.9)",
            fontFamily: "PPObjectSans-Regular",
            color: "#1B1D1A",
            margin: 0,
            zIndex: 1,
            borderBottomLeftRadius: "0.8vw",
            borderBottomRightRadius: "0.8vw",
          }}
        >
          {searchResults.map((event) => (
            <li
              key={event.event_id}
              onClick={() =>
                navigate(`/Event?event_id=${encodeURIComponent(event.event_id)}`)
              }
              style={{
                padding: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <div>{event.title}</div>
              <div style={{ fontSize: "12px", marginLeft: "5px" }} > in {event.category.name}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
