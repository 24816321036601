import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventSeatOutlinedIcon from "@mui/icons-material/EventSeatOutlined";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import LocalActivityRoundedIcon from "@mui/icons-material/LocalActivityRounded";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../../../hooks/useAuth";

export default function Menu() {
  const [state, setState] = React.useState({
    right: false,
  });

  const { user, logout } = useAuth();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleLogout = () => {
    logout();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 400, color: "#E8E8E8" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* "User" is rendered only if it exists */}
        {user && (
          <>
            <ListItem key={user} disablePadding sx={{ alignItems: "center" }}>
              <ListItemButton>
                <ListItemIcon>
                  <HeadsetMicRoundedIcon />
                </ListItemIcon>
                Hi!, <ListItemText primary={user} />
                <a>Edit profile</a> 
              </ListItemButton>
            </ListItem>
            <Divider color="#2A880A" />
          </>
        )}
        {["Payment Modes", "Help & Support", "Legal", "Privacy and policy"].map(
          (text, index) => (
            <>
              <ListItem key={index} sx={{ alignItems: "center" }}>
                <ListItemButton>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
              <hr style={{ color: "#2A880A", width: "80%" }} />
            </>
          ),
        )}
        {/* "Logout" is only rendered once logged in */}
        {user && (
          <ListItem
            disablePadding
            onClick={handleLogout}
            sx={{ color: "#D5910C", alignItems: "center" }}
          >
            <ListItemButton>
              <ListItemText
                primary="Logout"
                sx={{ fontFamily: "PPObjectSans-Heavy" }}
              />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}>
            <MenuIcon sx={{ fontSize: "30px", color: "#E8E8E8" }} />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            PaperProps={{
              sx: {
                height: 650,
                backgroundColor: "rgba(27, 30, 26, 0.7)",
                borderLeft: "#D5910C",
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
