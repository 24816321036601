import React from "react";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Location() {
  return (
    <Paper
      component="form"
      sx={{
        marginLeft: "10px",
        minWidth: "12vw",
        backgroundColor: "#E8E8E8",
        background: "transparent",
      }}
    >
      <FormControl fullWidth>
        <Autocomplete
          id="blur-on-select"
          blurOnSelect
          options={cities.map((city) => city.name)}
          sx={{
            fontWeight: "400",
            color: "#E8E8E8",
            marginTop: "0.8vh",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Location"
              variant="standard"
              sx={{
                "& input": {
                  color: "#E8E8E8",
                  fontFamily: "PPObjectSans-Heavy",
                  fontSize: "15px",
                  lineHeight: "3vh",
                  fontWeight: 800,
                  width: "auto",
                },
                "& .MuiInputLabel-root": {
                  fontFamily: "PPObjectSans-Regular",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  lineHeight: "3vh",
                  fontWeight: 400,
                  color: "#E8E8E8",
                },
              }}
            />
          )}
        />
      </FormControl>
    </Paper>
  );
}

const cities = [
  { name: "Carolina" },
  { name: "Chicago" },
  { name: "Dallas" },
  { name: "Miami" },
];
