import { Sparkle } from "lucide-react";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export default function Focus() {
  return (
    <Box
      sx={{
        mt: "10vh",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        alignItems: "center",
      }}
    >
      <Paper
        elevate={0}
        sx={{
          bgcolor: "#1B1D1A",
          borderTop: "1.5px solid #2A880A ",
          width: "26vw",
          height: "26vw",
          opacity: "1",
          borderRadius: "50%",
        }}
      />
      <Box position="absolute" mt={4} sx={{ display: "flex", width: "40%" }}>
        <Typography
          variant="h2"
          color="#E8E8E8"
          sx={{
            fontSize: "3.8vw",
            lineHeight: "5vw",
            whiteSpace: "wrap",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          <span style={{ fontFamily: "Baloo Chettan" }}>Spend</span>
          <span
            style={{
              fontFamily: "Baloo Chettan",
              color: "#2A880A",
              marginRight: "0.5vw",
            }}
          >
            .
          </span>
          <span style={{ font: "PPObjectSans-Regular" }}>
            {" "}
            to participate, learn, grow, and
          </span>
          <span style={{ font: "PPObjectSans-Regular", color: "#2A880A" }}>
            {" "}
            build your future
          </span>
          <br></br>
          <span
            style={{
              font: "PPObjectSans-Regular",
              color: "#E8E8E8",
              fontSize: "1.2vw",
            }}
          >
            Book your passes and make new steps for future.
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
