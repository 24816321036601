import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Button } from "@mui/material";
import { BellDot } from "lucide-react";

export default function AfterLogin(props) {
  const [bookingsAnchorEl, setBookingsAnchorEl] = useState(null);
  const [offersAnchorEl, setOffersAnchorEl] = useState(null);
  const handleBookingsMenuOpen = (event) => {
    setBookingsAnchorEl(event.currentTarget);
  };
  const handleBookingsMenuClose = () => {
    setBookingsAnchorEl(null);
  };

  const handleOffersMenuOpen = (event) => {
    setOffersAnchorEl(event.currentTarget);
  };

  const handleOffersMenuClose = () => {
    setOffersAnchorEl(null);
  };

  return (
    <>
      {/* My Bookings dropdown */}
      <>
        <Button
          id="bookings-button"
          aria-controls={bookingsAnchorEl ? "mybookings" : undefined}
          aria-haspopup="true"
          aria-expanded={bookingsAnchorEl ? "true" : undefined}
          onClick={handleBookingsMenuOpen}
          sx={{ color: "#E8E8E8", font: "PPObjectSans-Heavy", weight: "800" }}
        >
          My Bookings
        </Button>
        <Menu
          id="mybookings"
          anchorEl={bookingsAnchorEl}
          open={Boolean(bookingsAnchorEl)}
          onClose={handleBookingsMenuClose}
          MenuListProps={{
            "aria-labelledby": "bookings-button",
          }}
        >
          <MenuItem onClick={handleBookingsMenuClose}>ABC Event</MenuItem>
          <MenuItem onClick={handleBookingsMenuClose}>XYZ Concert</MenuItem>
          <MenuItem onClick={handleBookingsMenuClose}>Music Show</MenuItem>
        </Menu>
      </>

      {/* Offers dropdown */}
      <>
        <Button
          id="basic-button"
          aria-controls={offersAnchorEl ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={offersAnchorEl ? "true" : undefined}
          onClick={handleOffersMenuOpen}
          sx={{ color: "#E8E8E8", font: "PPObjectSans-Heavy", weight: "800" }}
        >
          Offers
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={offersAnchorEl}
          open={Boolean(offersAnchorEl)}
          onClose={handleOffersMenuClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleOffersMenuClose}>
            10% Off on all events
          </MenuItem>
          <MenuItem onClick={handleOffersMenuClose}>
            50% Cashback to every 5th customer
          </MenuItem>
          <MenuItem onClick={handleOffersMenuClose}>2% Cashback</MenuItem>
        </Menu>
      </>
      <IconButton
        sx={{
          color: "inherit", // Use the inherit color
          bgcolor: "transparent", // Set background color to transparent
        }}
      >
        <BellDot />
      </IconButton>
    </>
  );
}
