import React from "react";
import { Route, Routes, useRoutes } from "react-router-dom";
import HomePage from "./Components/HomePage";
import Login from "./Components/LoginAndSignup";
import { AuthProvider } from "./hooks/useAuth";
import { ProtectedRoute } from "./utils/ProtectedRoutes";
import Signup from "./Components/LoginAndSignup/Signup";
import Category from "./Components/Category/Category";
import EventScreen from "./Components/EventScreen/EventScreen";
import HostOnSpend from "./Components/HostOnSpend/HostOnSpend";
import BookingPage1 from "./Components/Booking/BookingPage1";
import BookingPage2 from "./Components/Booking/BookingPage2";
import Success from "./Components/Booking/Success";

export default function SpendRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/Category",
      element: <Category />,
    },
    {
      path: "/Event",
      element: <EventScreen />,
    },
    {
      path: "/hostonspend",
      element: <HostOnSpend />,
    },
    {
      path: "/booknow",
      element: <BookingPage1 />,
    },
    {
      path: "/booknow/step2",
      element: <BookingPage2 />,
    },
    {
      path: "/booknow/step2/Success",
      element: <Success />,
    },
  ]);
  return <AuthProvider>{routes}</AuthProvider>;
}
