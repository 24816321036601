import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  Link,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Left from "./Components/Left";
import Heading from "./Components/Heading";
import FormInputField from "./Components/FormInputField";
import OtherLogin from "./Components/OtherLogin";
import * as Style from "../style";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import { storeUserData } from "../../action/SpendActions";
import Otp from "./Otp";
import AccDetails from "./AccDetails";
import LoadingSpinner from "../LoadingSpinner";
import { API_ENDPOINT } from "../../Constants";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    newUser,
    login,
    error,
    apiError,
    showAccDetails,
    isLoading,
    setIsLoading,
  } = useAuth();
  const [showOTP, setShowOTP] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleFormSubmit(values, setSubmitting) {
    const apiUrl = `${API_ENDPOINT}/auth/signup`;
    setIsLoading(true);
    const postData = {
      mobileNumber: values.phone,
      email: values.email,
      otpRequired: true,
    };
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        if (responseData.isOtpSent && !responseData.isUserAvailable) {
          console.log("if:" + responseData.message);
          dispatch(storeUserData(values));
          newUser(values);
          error(null);
          setShowOTP(true);
        } else if (responseData.isUserAvailable) {
          error("User is already registered. Please Login");
        } else {
          error(responseData.message);
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        // Display error to the user
        error("An error occurred while sending data");
      })
      .finally(() => {
        // No matter what happens,
        setSubmitting(false);
        setIsLoading(false);
      });
  }

  return (
    <>
      {showOTP ? (
        <Otp />
      ) : showAccDetails ? (
        <AccDetails />
      ) : (
        <Grid
          container
          sx={{ height: "100vh" }}
          alignItems="center"
          justifyContent="center"
        >
          {/* Left */}
          <Grid item xs={12} sm={6}>
            <Left
              backgroundImage="linear-gradient(to left,  rgba(42, 136, 10,0.65), rgba(42, 136, 10,0.6))"
              background="radial-gradient(circle, rgba(42, 136, 10,0.7), rgba(42, 136, 10,0.65))"
              boxShadow="-200px 0 500px 100px rgba(42, 136, 10,0.6)"
            />
          </Grid>
          {/* Right */}
          <Grid item xs={12} sm={6} sx={{ height: "clamp(60vw,80vh,100%)" }}>
            <Paper elevation={0} sx={{ ...Style.rightPaper }}>
              <Box
                sx={{
                  ml: "clamp(55px,70px,100px)",
                  mt: "clamp(70px,180px,10vw)",
                }}
              >
                <Heading heading="Create an account" />
                {/* Form with Validation */}
                <Formik
                  initialValues={{
                    email: "",
                    phone: "",
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .test(
                        "valid-email",
                        "Please enter a valid email",
                        function (value) {
                          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          return emailRegex.test(value); // Check if value matches the email regex ||
                        },
                      )
                      .required("Please enter your email "),

                    phone: Yup.string()
                      .test(
                        "valid-mobile",
                        "Please enter a valid mobile number",
                        function (value) {
                          return Yup.string()
                            .length(10)
                            .matches(/^[0-9]+$/)
                            .isValidSync(value); // Check if value matches the email regex ||
                        },
                      )
                      .required("Please enter your phone number"),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values, setSubmitting);
                  }}
                >
                  {({ isSubmitting, values }) => (
                    <Form autoComplete="off">
                      <Box>
                        <FormControl sx={{ mb: 2 }}>
                          <Typography variant="h4" sx={Style.formLabel}>
                            Enter Email ID
                          </Typography>
                          <FormInputField
                            name="email"
                            placeholder="Enter Email"
                          />
                          {values.email && apiError && (
                            <Typography
                              variant="body2"
                              style={{ color: "#C1113B", fontSize: "14px" }}
                            >
                              {apiError}
                            </Typography>
                          )}
                          <ErrorMsg name="email" />
                        </FormControl>
                      </Box>
                      <Box>
                        <FormControl sx={{ mb: 2 }}>
                          <Typography variant="h4" sx={Style.formLabel}>
                            Enter Phone Number
                          </Typography>
                          <FormInputField
                            name="phone"
                            placeholder="Enter Phone Number"
                          />
                          {values.phone && apiError && (
                            <Typography
                              variant="body2"
                              style={{ color: "#C1113B", fontSize: "14px" }}
                            >
                              {apiError}
                            </Typography>
                          )}
                          <ErrorMsg name="phone" />
                        </FormControl>
                      </Box>
                      {isLoading ? (
                        <LoadingSpinner size={35} color="#2A880A" />
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={Style.submitBtn}
                          disabled={isSubmitting}
                        >
                          Submit
                        </Button>
                      )}
                    </Form>
                  )}
                </Formik>

                {/* Existing user link */}
                <Box sx={{ display: "flex", marginTop: "4vh" }}>
                  <Typography
                    variant="h4"
                    marginBottom={1}
                    sx={Style.formLabel}
                  >
                    Existing User?{" "}
                  </Typography>
                  <span
                    onClick={() => navigate("/login")}
                    style={Style.linkText}
                  >
                    Log in here
                  </span>
                </Box>

                {/* Other login Buttons */}
                <OtherLogin setSubmitting={setIsSubmitting} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Signup;
