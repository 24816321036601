import React from "react";
import { OutlinedInput } from "@mui/material";
import { Field } from "formik";

const FormInputField = ({ placeholder, name }) => {
  return (
    <Field
      as={OutlinedInput}
      placeholder={placeholder}
      name={name}
      sx={{
        bgcolor: "rgba(232, 232, 232, 0.1)",
        color: "#E8E8E8",
        width: "clamp(300px,23.5vw,430px)",
        height: "40px",
      }}
    />
  );
};

export default FormInputField;
