import React from "react";
import { ErrorMessage } from "formik";
import { Typography } from "@mui/material";

const ErrorMsg = ({ name }) => (
  <ErrorMessage name={name}>
    {(errorMessage) => (
      <Typography
        variant="body2"
        style={{ color: "#C1113B", fontSize: "14px" }}
      >
        {errorMessage}
      </Typography>
    )}
  </ErrorMessage>
);

export default ErrorMsg;
