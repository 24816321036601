import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingSpinner = ({ size, color }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress size={size} style={{ color }} />
    </Box>
  );
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(["#2A880A", "#D5910C"]),
};

LoadingSpinner.defaultProps = {
  size: 40,
  color: "#2A880A",
};

export default LoadingSpinner;
