const categories = [
  {
    name: "College Festivals",
    description:
      "College Fest is an event that celebrates college life. It includes a variety of different activities from concerts to carnivals. Tens of thousands of students from all over the world attend and participate in the festivities and make lifelong memories.",
    inclGradient: "linear-gradient(200.29deg, #2A880A 3.22%, #E8E8E8 97.7%)",
    linearGradient:
      "linear-gradient(to right, rgba(42, 136, 10,40%),rgba(42, 136, 10,10%), rgba(0, 0, 0, 0))",
    color: "#2A880A",
    img: "https://www.figma.com/file/ZhZUpNzRThEFGVDDBpPlz6/Spend.?type=design&node-id=257-96&mode=design&t=f6yASjvKVSf9XcEu-4",
  },
  {
    name: "Workshops",
    description:
      "Workshops are educational seminars or groups of educational and practical subjects. They involve presentations, discussions, and hands-on activities on a specific topic.",
    inclGradient: "linear-gradient(200.29deg, #D5910C 3.22%, #E8E8E8 97.7%)",
    linearGradient:
      "linear-gradient(to right, rgba(213, 145, 12,40%),rgba(213, 145, 12,10%), rgba(0, 0, 0, 0))",
    color: "#D5910C",
    img: "https://www.figma.com/file/ZhZUpNzRThEFGVDDBpPlz6/Spend.?type=design&node-id=257-127&mode=design&t=f6yASjvKVSf9XcEu-4",
  },
  {
    name: "Cultural Events",
    description:
      "Cultural events are activities, programs, and experiences that are planned, organized, and hosted by cultural organizations, institutions, communities, or individuals. These events celebrate and promote cultural diversity, heritage, traditions, and artistic expression.",
    inclGradient: "linear-gradient(200.29deg, #1A3E9A 3.22%, #E8E8E8 97.7%)",
    linearGradient:
      "linear-gradient(to right, rgba(26, 62, 154,40%),rgba(26, 62, 154,10%), rgba(0, 0, 0, 0))",
    color: "#1A3E9A",
    img: "https://www.figma.com/file/ZhZUpNzRThEFGVDDBpPlz6/Spend.?type=design&node-id=257-157&mode=design&t=f6yASjvKVSf9XcEu-4",
  },
  {
    name: "Concert Events",
    description:
      "Concert events are live music performances where musicians or bands perform for an audience. Concerts can feature a single artist or multiple artists and typically involve a range of musical genres.",
    inclGradient: "linear-gradient(200.29deg, #680AA1 3.22%, #E8E8E8 97.7%)",
    linearGradient:
      "linear-gradient(to right, rgba(104, 10, 161,40%),rgba(104, 10, 161,10%), rgba(0, 0, 0, 0))",
    color: "#680AA1",
    img: "https://www.figma.com/file/ZhZUpNzRThEFGVDDBpPlz6/Spend.?type=design&node-id=257-175&mode=design&t=f6yASjvKVSf9XcEu-4",
  },
  {
    name: "Sports Events",
    description:
      "Sports events are organized competitions or games in which athletes or teams compete against each other in various sports disciplines. These events can range from local, regional, national, to international levels.",
    inclGradient: "linear-gradient(200.29deg, #C1113B 3.22%, #E8E8E8 97.7%)",
    linearGradient:
      "linear-gradient(to right, rgba(193, 17, 59,40%),rgba(193, 17, 59,10%), rgba(0, 0, 0, 0))",
    color: "#C1113B",
    img: "https://www.figma.com/file/ZhZUpNzRThEFGVDDBpPlz6/Spend.?type=design&node-id=257-193&mode=design&t=f6yASjvKVSf9XcEu-4",
  },
];

const events = [
  {
    event_id: "1",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-U2F0LCAyNyBBcHI%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00393015-jgurcrmbba-portrait.jpg",
    },
    category: {
      name: "College Festivals",
      subcategory: null,
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "2",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-RnJpLCAxOSBBcHIgb253YXJkcw%3D%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00374267-bjxcljtnae-portrait.jpg",
    },
    category: {
      name: "Workshops",
      subcategory: "Tech workshop",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },

  {
    event_id: "3",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-VGh1LCAxOCBBcHIgb253YXJkcw%3D%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00391153-zvvdgbmbfk-portrait.jpg",
    },
    category: {
      name: "Cultural Events",
      subcategory: "Dance Show",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "4",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-U2F0LCAyNyBBcHI%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00393015-jgurcrmbba-portrait.jpg",
    },
    category: {
      name: "Concert Events",
      subcategory: "Music shows",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "5",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-U2F0LCAyNyBBcHI%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00393015-jgurcrmbba-portrait.jpg",
    },
    category: {
      name: "Sports Events",
      subcategory: "Running",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "6",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-U2F0LCAyNyBBcHI%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00393015-jgurcrmbba-portrait.jpg",
    },
    category: {
      name: "College Festivals",
      subcategory: null,
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "7",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://assets-in.bmscdn.com/discovery-catalog/events/tr:w-400,h-600,bg-CCCCCC:w-400.0,h-660.0,cm-pad_resize,bg-000000,fo-top:l-text,ie-U2F0LCAyNyBBcHI%3D,fs-29,co-FFFFFF,ly-612,lx-24,pa-8_0_0_0,l-end/et00393015-jgurcrmbba-portrait.jpg",
    },
    category: {
      name: "Workshops",
      subcategory: "Tech workshop",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },

  {
    event_id: "8",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?cultural-Events",
    },
    category: {
      name: "Cultural Events",
      subcategory: "Dance Show",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "9",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Concerts",
    },
    category: {
      name: "Concert Events",
      subcategory: "Music shows",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "10",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Sports-Events",
    },
    category: {
      name: "Sports Events",
      subcategory: "Running",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "11",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?college-festivals",
    },
    category: {
      name: "College Festivals",
      subcategory: null,
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "12",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Workshops",
    },
    category: {
      name: "Workshops",
      subcategory: "Tech workshop",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },

  {
    event_id: "13",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?cultural-Events",
    },
    category: {
      name: "Cultural Events",
      subcategory: "Dance Show",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "14",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Concerts",
    },
    category: {
      name: "Concert Events",
      subcategory: "Music shows",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "15",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Sports-Events",
    },
    category: {
      name: "Sports Events",
      subcategory: "Running",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "16",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?college-festivals",
    },
    category: {
      name: "College Festivals",
      subcategory: null,
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "17",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Workshops",
    },
    category: {
      name: "Workshops",
      subcategory: "Tech workshop",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },

  {
    event_id: "18",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?cultural-Events",
    },
    category: {
      name: "Cultural Events",
      subcategory: "Dance Show",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "19",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Concerts",
    },
    category: {
      name: "Concert Events",
      subcategory: "Music shows",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
  {
    event_id: "20",
    user_id: "rksasank2000@gmail.com",
    title: "Dummy Event",
    venue: "Bangalore cubbonpark",
    date: { from: "26 April 2024", to: "30 April 2024" },
    time: "10AM to 12 AM",
    price: null,
    description:
      "A national-level techno-management fest organized by the students of KL University. It is a two- day festival filled with activities, events, workshops, and student performances that drive the students to experience the joy in being part of a synergy.",
    image_loc: {
      poster: ["", ""],
      card: "https://source.unsplash.com/290x324/?Sports-Events",
    },
    category: {
      name: "Sports Events",
      subcategory: "Running",
    },
    artist_list: {
      name: "Sasank",
      image_loc: "",
    },
  },
];

const getFilteredEvents = (category, id = null) => {
  if (!category && !id) {
    return events; // Return all data if both category and id are null
  }
  if (id) {
    return events.filter((event) => event.event_id === id);
  } else {
    return events.filter((event) => event.category.name === category);
  }
};

function formatDateRange(dateFrom, dateTo) {
  const fromDate = new Date(dateFrom);
  const toDate = new Date(dateTo);

  const formattedFromDate = `${fromDate.getDate()}`;
  const formattedToDate = `${toDate.getDate()} ${getMonthName(toDate.getMonth())}`;
  const formattedDateRange = `${formattedFromDate} - ${formattedToDate} ${fromDate.getFullYear()}`;

  return formattedDateRange;
}

function getMonthName(monthIndex) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[monthIndex];
}

const getFormattedDateRange = (from, to) => {
  const formattedDateRange = formatDateRange(from, to);
  return formattedDateRange;
};

function getDateRangeArray(startDate, endDate) {
  const dates = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export {
  categories,
  events,
  getFilteredEvents,
  getFormattedDateRange,
  getDateRangeArray,
};
