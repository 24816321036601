import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  FormControl,
  ButtonGroup,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFilteredEvents,
  getFormattedDateRange,
  getDateRangeArray,
} from "../data";
import Navbar from "../HomePage/Navbars/Navbar";
import BreadCrumbs from "../BreadCrumbs";
import * as Style from "../style";
import AddIcon from "@mui/icons-material/Add";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormInputField from "../LoginAndSignup/Components/FormInputField";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import { useCustomHook } from "../../hooks/useCustomHook";

export default function BookingPage2(props) {
  const nav = useNavigate();
  const { storeData } = useCustomHook();

  // Fetch the event ID from the URL
  const location = useLocation();
  const [tickets, setTickets] = useState(1);
  const [selectedPass, setSelectedPass] = useState(0);
  const [userDetails, setUserDetails] = useState(false);
  const [formData, setFormData] = useState(
    Array.from({ length: tickets }, (_, index) => ({
      pass: index + 1,
      email: "",
      phone: "",
      name: "",
    })),
  );

  // Fetch the event details based on the event ID
  const event = getFilteredEvents(null, props.event_id)[0];
  const dateRange = getFormattedDateRange(event.date.from, event.date.to);

  const handleFormSubmit = (values, setSubmitting) => {
    setSubmitting(false);

    // Update formData with the current pass number and form values
    setFormData((prevData) =>
      prevData.map((data, index) =>
        index === selectedPass
          ? {
              ...data,
              pass: selectedPass + 1,
              ...values,
            }
          : data,
      ),
    );
    setUserDetails(true);
  };

  const handlePassBoxClick = (passIndex) => {
    setSelectedPass(passIndex);
  };

  const addTicket = () => {
    setTickets((prevTickets) => prevTickets + 1);
  };

  const reduceTicket = () => {
    if (tickets > 1) {
      setTickets((prevTickets) => prevTickets - 1);
    }
  };

  useEffect(() => {
    setFormData((prevData) =>
      prevData.length < tickets
        ? [
            ...prevData,
            ...Array.from(
              { length: tickets - prevData.length },
              (_, index) => ({
                pass: prevData.length + index + 1,
                email: "",
                phone: "",
                name: "",
              }),
            ),
          ]
        : prevData.slice(0, tickets),
    );
  }, [tickets]);

  const handleProceed = () => {
    console.log(formData);
    storeData(formData);
    nav(
      `/bookNow/step2/Success?event_id=${encodeURIComponent(event.event_id)}&passes=${encodeURIComponent(tickets)}&booking_id=${encodeURIComponent("1234567890")}`,
    );
  };
  return (
    <Box>
      <Navbar all={false} />
      <BreadCrumbs
        links={[
          { label: "Home", url: "/" },
          {
            label: event.category.name,
            url: `/Category?category=${encodeURIComponent(event.category.name)}`,
          },
          {
            label: event.title,
            url: `/Event?event_id=${encodeURIComponent(event.event_id)}`,
          },
          {
            label: "Book now",
            url: `/booknow?event_id=${encodeURIComponent(event.event_id)}`,
          },
        ]}
      />
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box sx={{ m: "5% 0% 0% 15%" }}>
          <Typography
            color="#2A880A"
            sx={{
              fontSize: "clamp(15px,3vw,30px)",
              fontFamily: "PPObjectSans-Regular",
            }}
          >
            {event.title}
          </Typography>
          {/* Details */}
          <Box
            sx={{
              color: "#E8E8E8",
              fontFamily: "PPObjectSans-Regular",
              fontSize: "0.8vw",
              width: "20vw",
            }}
          >
            <span>{dateRange}</span> | <span>{event.time}</span> |{" "}
            <span>{event.venue}</span>
          </Box>
          <Box sx={{ mt: "30%", display: "flex", gap: 2 }}>
            <Typography
              color="#E8E8E8"
              sx={{
                fontSize: "clamp(8px,1.2vw,30px)",
                fontFamily: "PPObjectSans-Regular",
              }}
            >
              Number of passes
            </Typography>
            <Box>
              <ButtonGroup variant="contained">
                <Button
                  onClick={addTicket}
                  sx={{
                    bgcolor: "#2A880A",
                    "&:hover": {
                      bgcolor: "rgba(42, 136, 10, 0.5)",
                      borderColor: "#2A880A",
                    },
                    width: "clamp(5px,2vw,140px)",
                  }}
                >
                  +
                </Button>
                <Button
                  sx={{
                    bgcolor: "#2A880A",
                    "&:hover": {
                      bgcolor: "rgba(42, 136, 10, 0.5)",
                      borderColor: "#2A880A",
                    },
                    width: "clamp(5px,2vw,140px)",
                  }}
                >
                  {tickets}
                </Button>
                <Button
                  onClick={reduceTicket}
                  sx={{
                    bgcolor: "#2A880A",
                    "&:hover": {
                      bgcolor: "rgba(42, 136, 10, 0.5)",
                      borderColor: "#2A880A",
                    },
                    width: "clamp(5px,1vw,15px)",
                  }}
                >
                  -
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>
        <Box sx={{ m: "5% 0% 0% 8%" }}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "#2A880A",
              width: "45vw",
            }}
          >
            <Typography color="#E8E8E8" fontSize="clamp(8px,1.5vw,14px)">
              Details of the participant
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 10,
              mt: "5%",
            }}
          >
            <Box>
              {/* Tickets and Details*/}
              <Box sx={{ display: "flex", gap: 10 }}>
                {[...Array(tickets)].map((_, index) => (
                  <Box key={index}>
                    <Box sx={{}}>
                      <Box
                        sx={{
                          background: "rgba(42, 136, 10, 0.1)",
                          p: "10%",
                          width: "clamp(10px,9vw,150px)",
                          display: "flex",
                          justifyContent: "space-between",
                          mb: 3,
                        }}
                        onClick={() => handlePassBoxClick(index)}
                      >
                        <Typography
                          fontFamily="PPObjectSans-Heavy"
                          color="#D5910C"
                          sx={{
                            fontWeight: 800,
                            fontSize: "clamp(8px,1.2vw,30px)",
                          }}
                        >
                          Pass {index + 1}
                        </Typography>
                        <Radio
                          checked={selectedPass === index}
                          onChange={() => handlePassBoxClick(index)}
                          color="primary"
                          sx={{
                            "&.Mui-checked": {
                              color: "#2A880A",
                            },
                          }}
                        />
                      </Box>
                      {selectedPass === index && (
                        <Formik
                          initialValues={formData[selectedPass]}
                          validationSchema={Yup.object({
                            email: Yup.string()
                              .test(
                                "valid-email",
                                "Please enter a valid email",
                                function (value) {
                                  const emailRegex =
                                    /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                  return emailRegex.test(value);
                                },
                              )
                              .required("Please enter your email "),

                            phone: Yup.string()
                              .test(
                                "valid-mobile",
                                "Please enter a valid mobile number",
                                function (value) {
                                  return Yup.string()
                                    .length(10)
                                    .matches(/^[0-9]+$/)
                                    .isValidSync(value);
                                },
                              )
                              .required("Please enter your phone number"),

                            name: Yup.string()
                              .required("Please enter your Name")
                              .min(6, "Please enter valid Name")
                              .max(128),
                          })}
                          onSubmit={(values, { setSubmitting }) => {
                            handleFormSubmit(values, setSubmitting);
                          }}
                        >
                          {({ isSubmitting }) => (
                            <Form autoComplete="off">
                              <Box>
                                <FormControl sx={{ mb: 2 }}>
                                  <Typography variant="h4" sx={Style.formLabel}>
                                    Name
                                  </Typography>
                                  <FormInputField
                                    name="name"
                                    placeholder="Name"
                                  />
                                  <ErrorMsg name="name" />
                                </FormControl>
                              </Box>
                              <Box>
                                <FormControl sx={{ mb: 2 }}>
                                  <Typography variant="h4" sx={Style.formLabel}>
                                    Phone Number
                                  </Typography>
                                  <FormInputField
                                    name="phone"
                                    placeholder="Enter Phone Number"
                                  />
                                  <ErrorMsg name="phone" />
                                </FormControl>
                              </Box>
                              <Box>
                                <FormControl sx={{ mb: 2 }}>
                                  <Typography variant="h4" sx={Style.formLabel}>
                                    Email ID
                                  </Typography>
                                  <FormInputField
                                    name="email"
                                    placeholder="Enter Email"
                                  />
                                  <ErrorMsg name="email" />
                                </FormControl>
                              </Box>
                              <Button
                                type="submit"
                                variant="contained"
                                sx={Style.submitBtn}
                                disabled={isSubmitting}
                              >
                                Add Details
                              </Button>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {userDetails && formData[tickets - 1]["email"].length > 0 && (
        <Box
          sx={{
            display: "flex",
            m: "5% 15%",
            justifyContent: "space-between",
            border: "2px solid",
            borderColor: "#2A880A",
            borderRadius: "clamp(8px,1vw,12px)",
            p: "2%",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(15px,1.7vw,30px)",
                color: "#E8E8E8",
              }}
            >
              Click proeed to get your{" "}
              <span
                style={{
                  color: "#2A880A",
                  fontSize: "clamp(12px,1.5vw,30px)",
                }}
              >
                {tickets} pass(es)
              </span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(5px,1vw,30px)",
                color: "#2A880A",
              }}
            >
              Proceed to book your seat(s)
            </Typography>
          </Box>
          <Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  ...Style.submitBtn,
                  width: "clamp(5px,7vw,100px)",
                  fontSize: "clamp(5px,0.75vw,14px)",
                }}
                onClick={handleProceed}
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
