import { Box, FormControl, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import Navbar from "../HomePage/Navbars/Navbar";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import FormInputField from "../LoginAndSignup/Components/FormInputField";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import * as Style from "../style";
import BreadCrumbs from "../BreadCrumbs";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CustomDatePicker = ({ name }) => {
  const { setFieldValue } = useFormikContext(); // Accessing formik context
  return (
    <DatePicker
      name={name}
      onChange={(newValue) => {
        setFieldValue(name, newValue);
      }}
      renderInput={(params) => <FormInputField {...params} />}
    />
  );
};

export default function HostOnSpend() {
  const [image, setImage] = useState(null);

  const handleFormSubmit = (values, setSubmitting) => {
    setSubmitting(false);
  };

  return (
    <>
      <Navbar />
      <BreadCrumbs
        links={[
          { label: "Home", url: "/" },
          {
            label: "Host On Spend.",
            url: `/hostonspend`,
          },
        ]}
      />
      <Box sx={{ m: "5% 15%" }}>
        <Formik
          initialValues={{
            title: "",
            category: "",
            date: "",
            time: "",
            venue: "",
            description: "",
          }}
          validationSchema={Yup.object({
            title: Yup.string().required("Please enter the event title"),
            category: Yup.string().required("Please enter the event category"),
            date: Yup.string().required("Please enter the event date"),
            time: Yup.string().required("Please enter the event time"),
            venue: Yup.string().required("Please enter the event venue"),
            description: Yup.string().required(
              "Please enter the event description",
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <FormControl sx={{ mb: 2 }}>
                <Typography sx={Style.formLabel}>Title</Typography>
                <FormInputField name="title" placeholder="Event name" />
                <ErrorMsg name="title" />
              </FormControl>

              <Box>
                <FormControl sx={{ mb: 2 }}>
                  <Typography sx={Style.formLabel}>Category</Typography>
                  <FormInputField
                    name="category"
                    placeholder="Category of event"
                  />
                  <ErrorMsg name="category" />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ mb: 2 }}>
                  <Typography sx={Style.formLabel}>Date</Typography>
                  <FormInputField
                    name="date"
                    placeholder="Enter Date dd/mm/yy"
                  />
                  <ErrorMsg name="date" />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ mb: 2 }}>
                  <Typography sx={Style.formLabel}>Time</Typography>
                  <FormInputField
                    name="time"
                    placeholder="Enter time of the event"
                  />
                  <ErrorMsg name="time" />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={{ mb: 2 }}>
                  <Typography sx={Style.formLabel}>Venue</Typography>
                  <FormInputField
                    name="venue"
                    placeholder="Enter venue of the event"
                  />
                  <ErrorMsg name="venue" />
                </FormControl>
              </Box>

              <Box>
                <FormControl sx={{ mb: 2 }}>
                  <Typography sx={Style.formLabel}>Description</Typography>
                  <FormInputField
                    name="description"
                    placeholder="Description of event"
                  />
                  <ErrorMsg name="description" />
                </FormControl>
              </Box>

              {/* Input for uploading image */}
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  top: "10%",
                  m: "5% 15%",
                  display: "flex",
                  height: "32vw",
                  width: "25vw",
                  background: "#E8E8E8",
                }}
              >
                <Box
                  sx={{
                    mt: "20%",
                    boxShadow: "-100px 0 100px 30px rgba(42, 136, 10,0.18)",
                    position: "absolute",
                    width: "90%",
                    height: "70%",
                    borderRadius: "50%",
                  }}
                />
                <FormControl sx={{ mb: 2 }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      setImage(URL.createObjectURL(e.target.files[0]))
                    }
                    style={{ display: "none" }}
                    id="image-upload"
                  />
                  <label
                    htmlFor="image-upload"
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "25vw",
                      height: "32vw",
                      background: "#E8E8E8",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <PhotoCameraIcon
                        sx={{
                          fontSize: "4rem",
                          color: "#757575",
                        }}
                      />
                    </Box>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) =>
                        setImage(URL.createObjectURL(e.target.files[0]))
                      }
                      style={{ display: "none" }}
                      id="image-upload"
                    />
                    <img
                      src={image || "placeholder_image_url"}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        opacity: image ? 1 : 0,
                        transition: "opacity 0.3s",
                      }}
                      alt="Event Image"
                    />
                  </label>
                </FormControl>

                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "1.5px",
                    backgroundImage:
                      "linear-gradient(to top, rgba(0, 0, 0, 0), #2A880A, rgba(0, 0, 0, 0))",
                  }}
                />
              </Box>

              <Button
                type="submit"
                variant="contained"
                sx={Style.submitBtn}
                disabled={isSubmitting}
              >
                Host event
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
