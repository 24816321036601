import React from "react";
import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  FormControl,
  Link,
  Grid,
} from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Left from "./Components/Left";
import Heading from "./Components/Heading";
import FormInputField from "./Components/FormInputField";
import * as Style from "../style";
import { useAuth } from "../../hooks/useAuth";
import ErrorMsg from "../ErrorMessage/ErrorMsg";
import AccDetails from "./AccDetails";
import LoadingSpinner from "../LoadingSpinner";
import { API_ENDPOINT } from "../../Constants";

const Otp = () => {
  const navigate = useNavigate();
  const { otp, user, isNewUser ,setIsLoading,isLoading} = useAuth();
  const [countdownActive, setCountdownActive] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => {
      setCountdownActive(false);
    }, 120000); // 2 minutes in milliseconds

    return () => clearTimeout(timer);
  }, []);

  async function handleResendClick() {
    const apiUrl = `${API_ENDPOINT}/auth/resendOTP`;
    const postData = {
      email: user,
    };
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData);
        setApiError(responseData.message);
        setCountdownActive(true);
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        setApiError("An error occurred while sending data");
      });
    setCountdownActive(false);
  }

  async function handleFormSubmit(values, setSubmitting) {
    setIsLoading(true);
    const apiUrl = `${API_ENDPOINT}/auth/verifyOTP`;
    const postData = {
      email: user,
      otp: values.otp,
    };
    try {
      // Send data to the API
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log(responseData);
      if (responseData.isOtpValid) {
        otp();
        if (isNewUser) {
          console.log("new user");
          setShowDetails(true);
        } else {
          navigate("/");
        }
        setApiError(null);
      } else {
        console.log(responseData.message);
        setApiError(responseData.message);
      }
    } catch (error) {
      console.error("Error sending data:", error);
      setApiError("An error occurred while sending data");
    }
    // sendData(apiUrl, postData);
    setSubmitting(false);
    setIsLoading(false);
  }
  return (
    <>
      {showDetails ? (
        <AccDetails />
      ) : (
        <Grid
          container
          sx={{ height: "100vh" }}
          alignItems="center"
          justifyContent="center"
        >
          {/* Left */}
          <Grid item xs={12} sm={6}>
            <Left
              backgroundImage="linear-gradient(to left,  rgba(42, 136, 10,0.65), rgba(42, 136, 10,0.6))"
              background="radial-gradient(circle, rgba(42, 136, 10,0.7), rgba(42, 136, 10,0.65))"
              boxShadow="-200px 0 500px 100px rgba(42, 136, 10,0.6)"
            />
          </Grid>

          {/* Right */}
          <Grid item xs={12} sm={6} sx={{ height: "clamp(60vw,68vh,100%)" }}>
            <Paper elevation={0} sx={{ ...Style.rightPaper }}>
              <Box>
                <Heading heading="Enter OTP" />
                <Box>
                  <Formik
                    initialValues={{
                      otp: "",
                    }}
                    validationSchema={Yup.object({
                      otp: Yup.string()
                        .required("Please enter OTP")
                        .matches(/^\d{6}$/, "Please enter a valid 6-digit OTP"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                      handleFormSubmit(values, setSubmitting);
                    }}
                  >
                    {({ isSubmitting, values }) => (
                      <Form autoComplete="off">
                        <Typography
                          variant="h4"
                          marginBottom={1}
                          sx={Style.formLabel}
                        >
                          OTP is sent to Email & Phone number
                        </Typography>
                        <FormControl
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "2.5vh",
                          }}
                        >
                          <FormInputField placeholder="Enter OTP" name="otp" />
                          {values.otp && apiError && (
                            <Typography
                              variant="body2"
                              style={{ color: "#C1113B", fontSize: "14px" }}
                            >
                              {apiError}
                            </Typography>
                          )}
                          <ErrorMsg name="otp" />
                        </FormControl>

                        <Box
                          display="flex"
                          sx={{ justifyContent: "Left", alignItems: "center" }}
                        >
                          {isLoading ? (
                            <LoadingSpinner size={35} color="#2A880A" />
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              sx={Style.submitBtn}
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          )}
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {countdownActive ? (
                              <Typography marginBottom={1} sx={Style.linkText}>
                                {" "}
                                Resend OTP <CountdownTimer />{" "}
                              </Typography>
                            ) : (
                              <Link
                                component="button"
                                onClick={handleResendClick}
                                sx={Style.linkText}
                              >
                                Resend OTP
                              </Link>
                            )}
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Otp;

const CountdownTimer = ({ minutes = 2, seconds = 0 }) => {
  const [time, setTime] = useState({ minutes, seconds });

  useEffect(() => {
    let timer = setInterval(() => {
      if (time.minutes === 0 && time.seconds === 0) {
        clearInterval(timer);
      } else {
        setTime((prevTime) => {
          if (prevTime.seconds === 0) {
            return { minutes: prevTime.minutes - 1, seconds: 59 };
          } else {
            return { minutes: prevTime.minutes, seconds: prevTime.seconds - 1 };
          }
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [time]);

  const formatTime = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  return (
    <>
      {(time.minutes > 0 || time.seconds > 0) && (
        <span>
          in {formatTime(time.minutes)}:{formatTime(time.seconds)}
        </span>
      )}
    </>
  );
};
