import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

const images = [
  { img: "https://source.unsplash.com/1600x900/?nature", title: "Nature" },
  { img: "https://source.unsplash.com/1600x900/?cars", title: "Car" },
  { img: "https://source.unsplash.com/1600x900/?planes", title: "Plane" },
  { img: "https://source.unsplash.com/1600x900/?bikes", title: "Bike" },
];

const MyCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000, // Transition speed (milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 3000, // Interval for automatic scrolling (milliseconds)
    cssEase: "linear", // Easing function for the animation
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          variableWidth: false, // Disable variable width on smaller screens to prevent horizontal scroll
        },
      },
    ],
  };

  return (
    <Box mt={4}>
      <Slider {...settings}>
        {images.map((image, i) => (
          <div
            key={i}
            style={{
              padding: "4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                width: "10%",
                height: "100%",
                background:
                  "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, -1))",
                borderRadius: "2.5vh",
                margin: 0,
              }}
            >
              <Typography
                m={7}
                sx={{ position: "absolute", fontSize: "5vw", color: "#E8E8E8" }}
              >
                {image.title}
              </Typography>
            </Box>
            <img src={image.img} alt={`Slide ${i + 1}`} className="SlideImg" />
          </div>
        ))}
      </Slider>
      <hr
        style={{
          border: "0",
          height: "2px",
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0),  #2A880A, rgba(0, 0, 0, 0))",
          width: "70vw",
          margin: "0 auto",
        }}
      />
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, rgba(42, 136, 10,0.6), rgba(42, 136, 10,0.65), rgba(42, 136, 10,0.6))",
          background:
            "radial-gradient(circle, rgba(42, 136, 10,0.7), rgba(42, 136, 10,0.65))",
          border: "1px 0.5 #D5910C ",
          boxShadow: "0 0 5vw 10vw rgba(42, 136, 10,0.6)",
          width: "50vw",
          height: "25vw",
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: "12%",
          top: "18vh",
          left: "25vw",
          borderRadius: "60%",
        }}
      />
    </Box>
  );
};

export default MyCarousel;
