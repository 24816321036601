import React, { useState } from "react";
import { Box, Typography, Button, Link } from "@mui/material";
import EventCard from "../EventCard/EventCard";

export default function LocationEvents(props) {
  const [showMore, setShowMore] = useState(false);
  const initialEventLimit = 4; // Initial number of events to display
  const eventLimit = showMore ? 8 : initialEventLimit; // Update the event limit based on showMore state

  // Function to toggle show more
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box sx={{ mb: 10 }}>
      <Box
        sx={{
          margin: "5% 15% 0% 15%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          fontFamily="PPObjectSans-Regular"
          variant="h3"
          color="#E8E8E8"
          sx={{
            fontSize: "clamp(15px,2vw,30px)",
            mb: 2,
          }}
        >
          {props.heading}
        </Typography>
        <Link
          onClick={handleShowMore}
          sx={{
            position: "relative",
            cursor: "pointer",
            color: "#D5910C",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
            fontSize: "clamp(7px,1vw,15px)",
          }}
        >
          {showMore ? "Show Less<<" : "Show More>>"}
        </Link>
      </Box>
      <Box margin="0% 15%">
        <EventCard categoryName={props.cards} limit={eventLimit} />
      </Box>
    </Box>
  );
}
