import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import Google from "./svg/Google.svg";
import { otherLoginBtn, otherLogin } from "../../style";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { storeUserData } from "../../../action/SpendActions";
import { useAuth } from "../../../hooks/useAuth";
import { API_ENDPOINT } from "../../../Constants";

export default function OtherLogin({ setSubmitting }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login, otp, error, newUser, setShowAccDetails ,setIsLoading} = useAuth();

  const handleGoogleLogin = (codeResponse) => {
    setIsLoading(true)
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${codeResponse.access_token}`,
            Accept: "application/json",
          },
        },
      )
      .then((res) => {
        const data = {
          name: res.data.name,
          email: res.data.email,
          verified: res.data.verified_email,
        };
        if (data.verified) {
          dispatch(storeUserData(data));
          handleSignup(data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSubmitting(false);
      });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleSignup = (values) => {
    const apiUrl = `${API_ENDPOINT}/auth/signup`;
    const postData = {
      mobileNumber: values.phone ? values.phone : null,
      email: values.email,
      otpRequired: false,
    };
    axios
      .post(apiUrl, postData)
      .then((response) => {
        const responseData = response.data;
        console.log(responseData);
        if (responseData.isUserAvailable) {
          error(null);
          otp();
          login(values.email);
          navigate("/");
          setIsLoading(false)
        } else {
          error(responseData.message);
          newUser(values);
          setShowAccDetails(true);
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
        error("An error occurred while sending data");
      });
      
  };

  return (
    <>
      {/* Divider */}
      <Box
        sx={{ margin: "9.2vh 0", display: "flex", alignItems: "center", ml: 2 }}
      >
        <Typography
          variant="span"
          sx={{
            fontWeight: 400,
            fontFamily: "PPObjectSans-Regular",
            lineHeight: "1.75vh",
            color: "#E8E8E8",
            marginRight: "2vh",
            fontSize: "1.75vh",
          }}
        >
          or
        </Typography>
        <hr
          style={{
            border: "0",
            height: "1px",
            backgroundImage:
              "linear-gradient(to right,  #2A880A, rgba(0, 0, 0, 0))",
            width: "50vh",
            margin: 0,
          }}
        />
      </Box>
      <Box
        sx={{
          marginTop: "4vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          ml: 2,
        }}
      >
        {/* Continue with Google */}
        <Button variant="contained" sx={otherLoginBtn} onClick={googleLogin}>
          <img src={Google} alt="Google" style={{ marginRight: "2.5vh" }} />
          <Typography variant="span" sx={otherLogin}>
            Continue with Google
          </Typography>
        </Button>

        {/* Continue with Apple */}
        <Button variant="contained" sx={otherLoginBtn}>
          <Typography variant="span" sx={{ marginRight: "2.5vh" }}>
            
          </Typography>
          <Typography variant="span" sx={otherLogin}>
            Continue with Apple
          </Typography>
        </Button>
      </Box>
    </>
  );
}
