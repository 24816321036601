import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [phone, setPhone] = useLocalStorage("phone", null);
  const [name, setName] = useLocalStorage("name", null);
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", false);
  const navigate = useNavigate();
  const [isNewUser, setNewUser] = useLocalStorage("isNewUser", null);
  const [apiError, setApiError] = useLocalStorage("apiError", null);
  const [isLoading, setIsLoading] = useLocalStorage("isLoading", false);
  const [showAccDetails, setShowAccDetails] = useLocalStorage(
    "showAccDetails",
    false,
  );

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    setNewUser(false);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    setIsLoggedIn(false);
    setShowAccDetails(false);
    navigate("/", { replace: true });
  };

  const newUser = (data) => {
    console.log(data);
    setUser(data.email);
    setPhone(data.phone);
    if (data.name) {
      setName(data.name);
    }
    setNewUser(true);
  };
  const otp = () => {
    setIsLoggedIn(true);
  };

  const error = (data) => {
    setApiError(data);
  };
  const value = useMemo(
    () => ({
      user,
      isLoggedIn,
      login,
      logout,
      newUser,
      otp,
      isNewUser,
      phone,
      error,
      apiError,
      name,
      showAccDetails,
      setShowAccDetails,
      isLoading,
      setIsLoading,
    }),
    [
      user,
      isLoggedIn,
      isNewUser,
      phone,
      error,
      apiError,
      name,
      showAccDetails,
      isLoading,
    ], // Make sure to include isLoggedIn in dependencies
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
