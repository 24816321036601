// Import necessary components and functions
import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFilteredEvents,
  getFormattedDateRange,
  getDateRangeArray,
} from "../data";
import Navbar from "../HomePage/Navbars/Navbar";
import BreadCrumbs from "../BreadCrumbs";
import * as Style from "../style";

export default function BookingPage1() {
  const nav = useNavigate();
  // Fetch the event ID from the URL
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("event_id");

  // Fetch the event details based on the event ID
  const event = getFilteredEvents(null, eventId)[0];
  const dateRange = getFormattedDateRange(event.date.from, event.date.to);
  const dateRangeArray = getDateRangeArray(event.date.from, event.date.to);

  // State variables to store the selected date and time slot
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  // Time slots available for selection
  const timeSlots = [
    { time: "10:00 AM - 11:00 AM" },
    { time: "11:00 AM - 12:00 PM" },
  ];

  // Function to handle date selection
  const handleDateSelect = (date) => {
    setSelectedDate(date);

    setSelectedTimeSlot(null);
  };
  // Function to handle time slot selection
  const handleTimeSlotSelect = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleProceed = (date, time, event_id) => {
    console.log(date, time);
    nav(`/booknow/step2?event_id=${encodeURIComponent(event_id)}`);
  };

  return (
    <Box>
      <Navbar all={false} />
      <BreadCrumbs
        links={[
          { label: "Home", url: "/" },
          {
            label: event.category.name,
            url: `/Category?category=${encodeURIComponent(event.category.name)}`,
          },
          {
            label: event.title,
            url: `/Event?event_id=${encodeURIComponent(event.event_id)}`,
          },
          {
            label: "Book now",
            url: `/booknow?event_id=${encodeURIComponent(event.event_id)}`,
          },
        ]}
      />
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box sx={{ m: "5% 0% 0% 15%" }}>
          <Typography
            color="#2A880A"
            sx={{
              fontSize: "clamp(15px,3vw,30px)",
              fontFamily: "PPObjectSans-Regular",
            }}
          >
            {event.title}
          </Typography>
          {/* Details */}
          <Box
            sx={{
              color: "#E8E8E8",
              fontFamily: "PPObjectSans-Regular",
              fontSize: "0.8vw",
              width: "20vw",
            }}
          >
            <span>{dateRange}</span> | <span>{event.time}</span> |{" "}
            <span>{event.venue}</span>
          </Box>
        </Box>
        <Box sx={{ m: "5% 0% 0% 8%" }}>
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "#2A880A",
              width: "45vw",
            }}
          >
            <Typography color="#E8E8E8" fontSize="clamp(8px,1.5vw,14px)">
              Choose the date and time
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              mt: "5%",
            }}
          >
            {/* Date picker */}
            {dateRangeArray.map((date, index) => (
              <Box key={index}>
                <Button
                  disableElevation
                  id={index}
                  variant={selectedDate === date ? "contained" : "outlined"}
                  onClick={() => handleDateSelect(date.toLocaleDateString())}
                  sx={{
                    marginBottom: "23px",
                    width: "clamp(50px,12vw,120px)",
                    height: "40px",
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "rgba(42, 136, 10, 0.5)",
                      borderColor: "#2A880A",
                    },
                    color: "#2A880A",
                    borderColor: "#2A880A",
                    fontSize: "clamp(8px,1.2vw,20px)",
                  }}
                >
                  {date.toLocaleDateString()}
                </Button>
                {/* Time slot picker */}
                {selectedDate === date.toLocaleDateString() && (
                  <RadioGroup
                    value={selectedTimeSlot}
                    onChange={(event) =>
                      handleTimeSlotSelect(event.target.value)
                    }
                    sx={{
                      color: "#E8E8E8",
                    }}
                  >
                    {timeSlots.map((slot, index) => (
                      <FormControlLabel
                        key={index}
                        sx={{
                          fontSize: "clamp(5px,1.2vw,20px)",
                          fontFamily: "PPObjectSans-Regular",
                        }}
                        value={slot.time}
                        control={
                          <Radio
                            sx={{
                              "&.Mui-checked": {
                                color: "#2A880A",
                              },
                              color: "#2A880A",
                            }}
                            color="primary"
                          />
                        }
                        label={slot.time}
                      />
                    ))}
                  </RadioGroup>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      {selectedDate && selectedTimeSlot && (
        <Box
          sx={{
            display: "flex",
            m: "15%",
            justifyContent: "space-between",
            border: "2px solid",
            borderColor: "#2A880A",
            borderRadius: "clamp(8px,1vw,12px)",
            p: "2%",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(15px,1.7vw,30px)",
                color: "#E8E8E8",
              }}
            >
              Get your pass for{" "}
              <span
                style={{ color: "#2A880A", fontSize: "clamp(12px,1.5vw,30px)" }}
              >
                {selectedDate} {selectedTimeSlot}
              </span>
            </Typography>
            <Typography
              sx={{
                fontFamily: "PPObjectSans-Regular",
                fontSize: "clamp(5px,1vw,30px)",
                color: "#2A880A",
              }}
            >
              Proceed to book your seat
            </Typography>
          </Box>
          <Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  ...Style.submitBtn,
                  width: "clamp(5px,7vw,100px)",
                  fontSize: "clamp(5px,0.75vw,14px)",
                }}
                onClick={() =>
                  handleProceed(selectedDate, selectedTimeSlot, event.event_id)
                }
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
