import { Box, Typography, IconButton } from "@mui/material";
import { Instagram, Facebook } from "lucide-react";
import XIcon from "@mui/icons-material/X";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Footer() {
  const handleShare = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: smooth scrolling behavior
    });
  };
  return (
    <Box
      sx={{
        background: "#2A880A",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "column",
        bottom: 0,
        height: "12vw",
        width: "100%",
      }}
    >
      <Typography
        fontFamily="Baloo Chettan"
        color="#E8E8E8"
        sx={{
          fontSize: "4vw",
          fontWeight: 400,
          lineHeight: "44px",
          letterSpacing: "-0.02em",
          textAlign: "center",
        }}
      >
        Spend.
      </Typography>
      <Box sx={{ display: "flex", gap:2, }}>
        <IconButton onClick={handleShare}>
          <Instagram color="#E8E8E8" size="clamp(10px,4vw,25px)" />
        </IconButton>
        <IconButton onClick={handleShare}>
          <Facebook color="#E8E8E8" size="clamp(10px,4vw,25px)" />
        </IconButton>
        <IconButton onClick={handleShare}>
          <XIcon sx={{ color: "#E8E8E8" ,fontSize: "clamp(10px,4vw,25px)" }} />
        </IconButton>
        <IconButton onClick={handleShare}>
          <WhatsAppIcon sx={{ color: "#E8E8E8" ,fontSize: "clamp(10px,4vw,25px)" }} />
        </IconButton>
      </Box>
    </Box>
  );
}
